import React from 'react';
import { Shield, AlertTriangle, Clock } from 'lucide-react';
import { activities } from './mockData';

const ActivityLog: React.FC = () => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Recent Activity</h2>
        <button className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1">
          <Clock className="w-4 h-4" />
          View All
        </button>
      </div>
      <div className="space-y-4">
        {activities.map((activity, index) => (
          <div key={index} className="flex items-start gap-3 pb-4 border-b border-gray-100 last:border-0">
            <div className={`p-2 rounded-lg ${
              activity.type === 'success' ? 'bg-green-100' : 'bg-yellow-100'
            }`}>
              {activity.type === 'success' ? (
                <Shield className="w-5 h-5 text-green-600" />
              ) : (
                <AlertTriangle className="w-5 h-5 text-yellow-600" />
              )}
            </div>
            <div className="flex-1">
              <p className="font-medium text-gray-900">{activity.message}</p>
              <p className="text-sm text-gray-600">Domain: {activity.domain}</p>
              <p className="text-xs text-gray-400 mt-1">{activity.timestamp}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityLog;