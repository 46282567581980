import React from 'react';
import { Play, Square, Trash2, Edit, ExternalLink, Calendar, Users } from 'lucide-react';
import { Lure } from '../../types';
import { usePhishlets } from '../../hooks/usePhishlets';

interface LureCardProps {
  lure: Lure;
  onToggle: (id: string) => void;
  onDelete: (id: string) => void;
  onEdit: () => void;
}

const LureCard: React.FC<LureCardProps> = ({ lure, onToggle, onDelete, onEdit }) => {
  const { phishlets } = usePhishlets();
  const phishlet = phishlets.find(p => p.id === lure.phishletId);

  const isExpired = lure.expiryDate && new Date(lure.expiryDate) < new Date();
  const isMaxVisitsReached = lure.maxVisits && lure.currentVisits && lure.currentVisits >= lure.maxVisits;

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold text-gray-900">
              {phishlet?.hostname}{lure.path}
            </h3>
            <span className={`px-2 py-1 text-xs rounded-full ${
              lure.active 
                ? 'bg-green-100 text-green-700' 
                : 'bg-gray-100 text-gray-700'
            }`}>
              {lure.active ? 'Active' : 'Inactive'}
            </span>
            {isExpired && (
              <span className="px-2 py-1 text-xs bg-red-100 text-red-700 rounded-full">
                Expired
              </span>
            )}
            {isMaxVisitsReached && (
              <span className="px-2 py-1 text-xs bg-yellow-100 text-yellow-700 rounded-full">
                Max Visits Reached
              </span>
            )}
          </div>
          <p className="text-sm text-gray-500 mt-1">
            Redirects to: {lure.redirectUrl}
          </p>
          {lure.params && Object.keys(lure.params).length > 0 && (
            <div className="mt-2 flex flex-wrap gap-2">
              {Object.entries(lure.params).map(([key, value]) => (
                <span key={key} className="px-2 py-1 text-xs bg-blue-100 text-blue-700 rounded-full">
                  {key}={value}
                </span>
              ))}
            </div>
          )}
        </div>
        
        <div className="flex gap-2">
          <button 
            onClick={() => onToggle(lure.id)}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title={lure.active ? 'Deactivate' : 'Activate'}
            disabled={isExpired || isMaxVisitsReached}
          >
            {lure.active ? (
              <Square className="w-5 h-5" />
            ) : (
              <Play className="w-5 h-5" />
            )}
          </button>
          <button 
            onClick={onEdit}
            className="p-2 text-gray-600 hover:text-yellow-600 hover:bg-yellow-50 rounded-lg transition-colors"
            title="Edit"
          >
            <Edit className="w-5 h-5" />
          </button>
          <a
            href={`https://${phishlet?.hostname}${lure.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Open URL"
          >
            <ExternalLink className="w-5 h-5" />
          </a>
          <button 
            onClick={() => onDelete(lure.id)}
            className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            title="Delete"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="flex items-center gap-2">
          <Users className="w-4 h-4 text-gray-400" />
          <div>
            <p className="text-xs text-gray-500">Visits</p>
            <p className="text-sm font-medium">
              {lure.currentVisits || 0} / {lure.maxVisits || '∞'}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Calendar className="w-4 h-4 text-gray-400" />
          <div>
            <p className="text-xs text-gray-500">Expires</p>
            <p className="text-sm font-medium">
              {lure.expiryDate 
                ? new Date(lure.expiryDate).toLocaleDateString()
                : 'Never'
              }
            </p>
          </div>
        </div>
        <div>
          <p className="text-xs text-gray-500">Captures</p>
          <p className="text-sm font-medium">{lure.captures}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Phishlet</p>
          <p className="text-sm font-medium">{phishlet?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default LureCard;