import React from 'react';
import { X, Download, Copy } from 'lucide-react';
import { CapturedData } from '../../types';

interface CookiesModalProps {
  capture: CapturedData;
  onClose: () => void;
  onExport: () => void;
}

const CookiesModal: React.FC<CookiesModalProps> = ({ capture, onClose, onExport }) => {
  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Captured Cookies</h2>
          <div className="flex gap-2">
            <button
              onClick={onExport}
              className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700"
            >
              <Download className="w-4 h-4" />
              Export Cookies
            </button>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="p-4">
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-500">Domain: {capture.domain}</span>
              <span className="text-sm text-gray-500">Total Cookies: {capture.cookies?.length || 0}</span>
            </div>
          </div>

          <div className="space-y-2 max-h-96 overflow-y-auto">
            {capture.cookies?.map((cookie, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium text-gray-900">{cookie.name}</span>
                      <span className="text-sm text-gray-500">@ {cookie.domain}</span>
                    </div>
                    <div className="mt-1 text-sm text-gray-600 break-all">
                      Path: {cookie.path}
                    </div>
                  </div>
                  <button
                    onClick={() => copyToClipboard(cookie.value)}
                    className="p-1 text-gray-400 hover:text-blue-600"
                    title="Copy Value"
                  >
                    <Copy className="w-4 h-4" />
                  </button>
                </div>
                <div className="mt-2">
                  <div className="text-sm text-gray-600 break-all">
                    <span className="font-medium">Value: </span>
                    {cookie.value.length > 50 ? `${cookie.value.substring(0, 50)}...` : cookie.value}
                  </div>
                </div>
                {cookie.expires && (
                  <div className="mt-1 text-xs text-gray-500">
                    Expires: {new Date(cookie.expires).toLocaleString()}
                  </div>
                )}
              </div>
            ))}

            {(!capture.cookies || capture.cookies.length === 0) && (
              <div className="text-center text-gray-500 py-4">
                No cookies captured for this session.
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end p-4 border-t border-gray-200">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;