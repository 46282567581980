import React from 'react';
import { AlertTriangle, Info, AlertCircle } from 'lucide-react';
import { SecurityLog } from '../../types';

interface SecurityLogEntryProps {
  log: SecurityLog;
}

const SecurityLogEntry: React.FC<SecurityLogEntryProps> = ({ log }) => {
  const getIcon = () => {
    switch (log.type) {
      case 'error':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
      default:
        return <Info className="w-5 h-5 text-blue-500" />;
    }
  };

  const getTypeColor = () => {
    switch (log.type) {
      case 'error':
        return 'bg-red-100 text-red-700';
      case 'warning':
        return 'bg-yellow-100 text-yellow-700';
      default:
        return 'bg-blue-100 text-blue-700';
    }
  };

  return (
    <div className="grid grid-cols-4 gap-4 p-4 items-center hover:bg-gray-50">
      <div className="text-sm text-gray-600">
        {new Date(log.timestamp).toLocaleString()}
      </div>
      <div>
        <span className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs ${getTypeColor()}`}>
          {getIcon()}
          {log.type}
        </span>
      </div>
      <div className="text-sm text-gray-900">{log.source}</div>
      <div className="text-sm text-gray-600">{log.message}</div>
    </div>
  );
};

export default SecurityLogEntry;