import React, { useState } from 'react';
import { Power, Download, Trash2, Eye, Globe, Activity, Settings } from 'lucide-react';
import { Phishlet } from '../../types';
import PhishletSettings from './PhishletSettings';

interface PhishletCardProps {
  phishlet: Phishlet;
  onToggle: (id: string) => void;
  onDelete: (id: string) => void;
  onExport: (id: string) => void;
  onUpdateSettings?: (id: string, settings: Partial<Phishlet>) => void;
}

const PhishletCard: React.FC<PhishletCardProps> = ({
  phishlet,
  onToggle,
  onDelete,
  onExport,
  onUpdateSettings
}) => {
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6 hover:border-red-200 transition-colors">
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <div className="flex items-center gap-3">
            <h3 className="text-xl font-semibold text-gray-900">{phishlet.name}</h3>
            <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
              phishlet.status === 'enabled'
                ? 'bg-green-100 text-green-700'
                : 'bg-gray-100 text-gray-700'
            }`}>
              {phishlet.status}
            </span>
            <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
              phishlet.visibility === 'visible'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-700'
            }`}>
              {phishlet.visibility}
            </span>
          </div>
          <div className="flex items-center gap-2 text-gray-500">
            <Globe className="w-4 h-4" />
            <span className="text-sm">{phishlet.hostname}</span>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => onToggle(phishlet.id)}
            className={`p-2 rounded-lg transition-colors ${
              phishlet.status === 'enabled'
                ? 'text-green-600 hover:bg-green-50'
                : 'text-gray-400 hover:bg-gray-50'
            }`}
            title={phishlet.status === 'enabled' ? 'Disable' : 'Enable'}
          >
            <Power className="w-5 h-5" />
          </button>
          <button
            onClick={() => setShowSettings(true)}
            className="p-2 text-gray-400 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Settings"
          >
            <Settings className="w-5 h-5" />
          </button>
          <button
            onClick={() => onExport(phishlet.id)}
            className="p-2 text-gray-400 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Export"
          >
            <Download className="w-5 h-5" />
          </button>
          <button
            onClick={() => onDelete(phishlet.id)}
            className="p-2 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            title="Delete"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 mt-6">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-gray-100 rounded-lg">
            <Activity className="w-5 h-5 text-gray-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Status</p>
            <p className="font-medium text-gray-900">{phishlet.status}</p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div className="p-2 bg-gray-100 rounded-lg">
            <Eye className="w-5 h-5 text-gray-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Captures</p>
            <p className="font-medium text-gray-900">{phishlet.captures}</p>
          </div>
        </div>
      </div>

      <div className="mt-6 pt-6 border-t border-gray-100">
        <div className="flex justify-between text-sm">
          <span className="text-gray-500">Last Active: {phishlet.lastActive}</span>
          <a href={phishlet.unauthUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">
            View Auth URL
          </a>
        </div>
      </div>

      {showSettings && (
        <PhishletSettings
          phishlet={phishlet}
          onClose={() => setShowSettings(false)}
          onUpdate={(settings) => {
            onUpdateSettings?.(phishlet.id, settings);
            setShowSettings(false);
          }}
        />
      )}
    </div>
  );
};

export default PhishletCard;