import { useEffect, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';
import { useNotifications } from './useNotifications';

let socket: Socket | null = null;

export const useSocket = () => {
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (!socket) {
      socket = io(window.location.origin, {
        path: '/socket.io',
        transports: ['polling', 'websocket'],
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
        autoConnect: true
      });

      socket.on('connect', () => {
        addNotification({
          message: 'Connected to server',
          type: 'success'
        });
      });

      socket.on('disconnect', () => {
        addNotification({
          message: 'Disconnected from server',
          type: 'warning'
        });
      });

      socket.on('error', (error: any) => {
        addNotification({
          message: error.message || 'Server error',
          type: 'error'
        });
      });
    }

    return () => {
      if (socket) {
        socket.removeAllListeners();
      }
    };
  }, [addNotification]);

  const emit = useCallback((event: string, data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        reject(new Error('Socket not connected'));
        return;
      }

      socket.emit(event, data, (response: any) => {
        if (response.success) {
          resolve(response.data);
        } else {
          reject(new Error(response.error));
        }
      });
    });
  }, []);

  return {
    socket,
    emit,
    connected: socket?.connected || false
  };
};

export default useSocket;