import { useState, useCallback } from 'react';
import { BlacklistEntry } from '../types';

export const useBlacklist = () => {
  const [blacklist, setBlacklist] = useState<BlacklistEntry[]>([
    {
      id: '1',
      ip: '192.168.1.0/24',
      addedDate: '2024-02-20T10:00:00'
    },
    {
      id: '2',
      ip: '10.0.0.5',
      addedDate: '2024-02-20T09:30:00'
    }
  ]);

  const addEntry = useCallback((ip: string) => {
    const newEntry: BlacklistEntry = {
      id: Date.now().toString(),
      ip,
      addedDate: new Date().toISOString()
    };
    setBlacklist(prev => [...prev, newEntry]);
  }, []);

  const removeEntry = useCallback((id: string) => {
    setBlacklist(prev => prev.filter(entry => entry.id !== id));
  }, []);

  return { blacklist, addEntry, removeEntry };
};