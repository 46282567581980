import React, { useState } from 'react';
import { useBlacklist } from '../../hooks/useBlacklist';
import { Plus, Trash2, Search } from 'lucide-react';

const BlacklistView: React.FC = () => {
  const { blacklist, addEntry, removeEntry } = useBlacklist();
  const [newEntry, setNewEntry] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEntries = blacklist.filter(entry =>
    entry.ip.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newEntry.trim()) {
      addEntry(newEntry.trim());
      setNewEntry('');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search blacklisted IPs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={newEntry}
            onChange={(e) => setNewEntry(e.target.value)}
            placeholder="Enter IP address or range"
            className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add
          </button>
        </form>
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="grid grid-cols-3 gap-4 p-4 border-b border-gray-200 bg-gray-50 rounded-t-xl">
          <div className="font-medium text-gray-500">IP Address/Range</div>
          <div className="font-medium text-gray-500">Added Date</div>
          <div className="font-medium text-gray-500">Actions</div>
        </div>
        
        <div className="divide-y divide-gray-200">
          {filteredEntries.map((entry) => (
            <div key={entry.id} className="grid grid-cols-3 gap-4 p-4 items-center hover:bg-gray-50">
              <div className="text-sm font-medium text-gray-900">{entry.ip}</div>
              <div className="text-sm text-gray-500">
                {new Date(entry.addedDate).toLocaleString()}
              </div>
              <div>
                <button
                  onClick={() => removeEntry(entry.id)}
                  className="p-2 text-gray-400 hover:text-red-600 transition-colors"
                  title="Remove from Blacklist"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}

          {filteredEntries.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              {searchTerm ? 'No matching IPs found' : 'No blacklisted IPs'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlacklistView;