import React, { useEffect } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import PhishletsView from './components/PhishletsView/PhishletsView';
import DomainsView from './components/DomainsView/DomainsView';
import LuresView from './components/LuresView/LuresView';
import SessionsView from './components/SessionsView/SessionsView';
import CapturedDataView from './components/CapturedData/CapturedDataView';
import BlacklistView from './components/BlacklistView/BlacklistView';
import Console from './components/Console/Console';
import SecurityLogsView from './components/SecurityLogs/SecurityLogsView';
import SettingsView from './components/SettingsView/SettingsView';
import NotificationBadge from './components/Notifications/NotificationBadge';
import { useSocket } from './hooks/useSocket';

const App: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('Dashboard');

  // Initialize WebSocket connection
  useSocket();

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Phishlets':
        return <PhishletsView />;
      case 'Domains':
        return <DomainsView />;
      case 'Lures':
        return <LuresView />;
      case 'Active Sessions':
        return <SessionsView />;
      case 'Captured Data':
        return <CapturedDataView />;
      case 'Blacklist':
        return <BlacklistView />;
      case 'Console':
        return <Console />;
      case 'Security Logs':
        return <SecurityLogsView />;
      case 'Settings':
        return <SettingsView />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Sidebar activeTab={activeTab} onTabChange={handleTabChange} />
      
      <main className="ml-64 p-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{activeTab}</h1>
            <p className="text-gray-600">Manage your security operations</p>
          </div>
          <NotificationBadge />
        </div>

        {renderContent()}
      </main>
    </div>
  );
};

export default App;