import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { useLures } from '../../hooks/useLures';
import LureCard from './LureCard';
import LureForm from './LureForm';

const LuresView: React.FC = () => {
  const { lures, addLure, toggleLure, deleteLure, updateLure } = useLures();
  const [showForm, setShowForm] = useState(false);
  const [editingLure, setEditingLure] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredLures = lures.filter(lure => 
    lure.path.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lure.redirectUrl.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search lures by path or redirect URL..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          <Plus className="w-4 h-4" />
          <span className="hidden sm:inline">Create Lure</span>
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {filteredLures.map((lure) => (
          <LureCard
            key={lure.id}
            lure={lure}
            onToggle={toggleLure}
            onDelete={deleteLure}
            onEdit={() => setEditingLure(lure.id)}
          />
        ))}

        {filteredLures.length === 0 && (
          <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
            <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
              <Plus className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-1">No lures found</h3>
            <p className="text-gray-500">
              {searchTerm ? 'Try adjusting your search terms' : 'Get started by creating a new lure'}
            </p>
          </div>
        )}
      </div>

      {(showForm || editingLure) && (
        <LureForm
          lure={editingLure ? lures.find(l => l.id === editingLure) : undefined}
          onClose={() => {
            setShowForm(false);
            setEditingLure(null);
          }}
          onSubmit={(data) => {
            if (editingLure) {
              updateLure(editingLure, data);
            } else {
              addLure({
                id: Date.now().toString(),
                ...data,
                active: false,
                captures: 0
              });
            }
            setShowForm(false);
            setEditingLure(null);
          }}
        />
      )}
    </div>
  );
};

export default LuresView;