import React, { useState, useRef, useEffect } from 'react';
import { Terminal as TerminalIcon, Copy, Download } from 'lucide-react';
import { usePhishlets } from '../../hooks/usePhishlets';
import { useSessions } from '../../hooks/useSessions';
import { LogEntry } from '../../types';
import { CommandProcessor } from '../../utils/commandProcessor';
import CommandInput from './CommandInput';
import LogEntryComponent from './LogEntry';

const Console: React.FC = () => {
  const { phishlets, togglePhishlet } = usePhishlets();
  const { sessions } = useSessions();
  const [logs, setLogs] = useState<LogEntry[]>([
    {
      id: '1',
      type: 'info',
      content: 'SecOps Portal Console v1.0.0',
      timestamp: new Date().toISOString()
    },
    {
      id: '2',
      type: 'info',
      content: "Type 'help' for available commands",
      timestamp: new Date().toISOString()
    }
  ]);
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const consoleEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    consoleEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [logs]);

  const handleCommand = (command: string) => {
    setCommandHistory(prev => [...prev, command]);
    setHistoryIndex(-1);

    const commandLog: LogEntry = {
      id: Date.now().toString(),
      type: 'command',
      content: command,
      timestamp: new Date().toISOString()
    };

    const result = CommandProcessor.process(command, { phishlets, sessions });

    if (result.message === 'CLEAR_CONSOLE') {
      setLogs([]);
    } else {
      setLogs(prev => [
        ...prev,
        commandLog,
        {
          id: (Date.now() + 1).toString(),
          type: result.type,
          content: result.message,
          timestamp: new Date().toISOString()
        }
      ]);

      const [cmd, domain] = command.trim().toLowerCase().split(' ');
      if ((cmd === 'start' || cmd === 'stop') && domain) {
        const phishlet = phishlets.find(p => p.domain === domain);
        if (phishlet) {
          togglePhishlet(phishlet.id);
        }
      }
    }
  };

  const copyLogs = () => {
    const logText = logs
      .map(log => `[${new Date(log.timestamp).toLocaleTimeString()}] ${log.type}: ${log.content}`)
      .join('\n');
    navigator.clipboard.writeText(logText);
  };

  const downloadLogs = () => {
    const logText = logs
      .map(log => `[${new Date(log.timestamp).toLocaleTimeString()}] ${log.type}: ${log.content}`)
      .join('\n');
    const blob = new Blob([logText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'console-logs.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <TerminalIcon className="w-6 h-6 text-gray-700" />
          <h2 className="text-2xl font-bold text-gray-900">Console</h2>
        </div>
        <div className="flex gap-2">
          <button
            onClick={copyLogs}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Copy Logs"
          >
            <Copy className="w-5 h-5" />
          </button>
          <button
            onClick={downloadLogs}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Download Logs"
          >
            <Download className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="bg-gray-900 rounded-xl shadow-sm p-4 h-[600px] flex flex-col">
        <div className="flex-1 overflow-y-auto font-mono text-sm">
          {logs.map((log) => (
            <LogEntryComponent
              key={log.id}
              type={log.type}
              content={log.content}
              timestamp={log.timestamp}
            />
          ))}
          <div ref={consoleEndRef} />
        </div>
        
        <CommandInput
          onSubmit={handleCommand}
          commandHistory={commandHistory}
          historyIndex={historyIndex}
          onHistoryChange={setHistoryIndex}
        />
      </div>
    </div>
  );
};

export default Console;