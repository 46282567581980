import React, { useState } from 'react';
import { usePhishlets } from '../../hooks/usePhishlets';
import StatCard from '../StatCard/StatCard';
import ActivityLog from '../ActivityLog/ActivityLog';
import BlackHoleControl from '../BlackHoleControl/BlackHoleControl';
import PhishletForm from '../PhishletForm/PhishletForm';
import { Target, Users, Shield, AlertTriangle, Power, Eye, Globe } from 'lucide-react';

const Dashboard: React.FC = () => {
  const { phishlets, togglePhishlet } = usePhishlets();
  const [showPhishletForm, setShowPhishletForm] = useState(false);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Active Campaigns"
          value={phishlets.length}
          icon={Target}
          trend={8}
          color="bg-blue-500"
        />
        <StatCard
          title="Captured Sessions"
          value={phishlets.reduce((acc, p) => acc + p.captures, 0)}
          icon={Users}
          trend={12}
          color="bg-green-500"
        />
        <StatCard
          title="Protected Domains"
          value={phishlets.filter(p => p.status === 'enabled').length}
          icon={Shield}
          trend={-3}
          color="bg-purple-500"
        />
        <StatCard
          title="Security Alerts"
          value={7}
          icon={AlertTriangle}
          trend={15}
          color="bg-red-500"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Active Phishlets</h2>
            <button 
              onClick={() => setShowPhishletForm(true)}
              className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            >
              + New Phishlet
            </button>
          </div>
          
          <div className="space-y-4">
            {phishlets.map((phishlet) => (
              <div key={phishlet.id} className="bg-white rounded-xl border border-gray-200 p-4 hover:border-red-200 transition-colors">
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <div className="flex items-center gap-3">
                      <h3 className="text-lg font-semibold text-gray-900">{phishlet.name}</h3>
                      <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
                        phishlet.status === 'enabled'
                          ? 'bg-green-100 text-green-700'
                          : 'bg-gray-100 text-gray-700'
                      }`}>
                        {phishlet.status}
                      </span>
                      <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
                        phishlet.visibility === 'visible'
                          ? 'bg-blue-100 text-blue-700'
                          : 'bg-gray-100 text-gray-700'
                      }`}>
                        {phishlet.visibility}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-500">
                      <Globe className="w-4 h-4" />
                      <span className="text-sm">{phishlet.hostname}</span>
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <button
                      onClick={() => togglePhishlet(phishlet.id)}
                      className={`p-2 rounded-lg transition-colors ${
                        phishlet.status === 'enabled'
                          ? 'text-green-600 hover:bg-green-50'
                          : 'text-gray-400 hover:bg-gray-50'
                      }`}
                      title={phishlet.status === 'enabled' ? 'Disable' : 'Enable'}
                    >
                      <Power className="w-5 h-5" />
                    </button>
                    <button
                      className="p-2 text-gray-400 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                      title="View Details"
                    >
                      <Eye className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <p className="text-xs text-gray-500">Captures</p>
                    <p className="text-sm font-medium text-gray-900">{phishlet.captures}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500">Last Active</p>
                    <p className="text-sm font-medium text-gray-900">{phishlet.lastActive}</p>
                  </div>
                </div>
              </div>
            ))}

            {phishlets.length === 0 && (
              <div className="text-center py-8">
                <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
                  <Target className="w-8 h-8 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-1">No phishlets configured</h3>
                <p className="text-gray-500">Get started by creating a new phishlet</p>
              </div>
            )}
          </div>
        </div>
        
        <div className="space-y-6">
          <BlackHoleControl />
          <ActivityLog />
        </div>
      </div>

      {showPhishletForm && (
        <PhishletForm 
          onClose={() => setShowPhishletForm(false)}
          onSubmit={(data) => {
            // Handle phishlet creation
            console.log('Creating phishlet:', data);
            setShowPhishletForm(false);
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;