import React, { useState, KeyboardEvent, useRef, useEffect } from 'react';

interface CommandInputProps {
  onSubmit: (command: string) => void;
  commandHistory: string[];
  historyIndex: number;
  onHistoryChange: (index: number) => void;
}

const CommandInput: React.FC<CommandInputProps> = ({
  onSubmit,
  commandHistory,
  historyIndex,
  onHistoryChange
}) => {
  const [command, setCommand] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!command.trim()) return;
    onSubmit(command);
    setCommand('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        onHistoryChange(newIndex);
        setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        onHistoryChange(newIndex);
        setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      } else if (historyIndex === 0) {
        onHistoryChange(-1);
        setCommand('');
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      // Implement command auto-completion here
      const suggestions = getCommandSuggestions(command);
      if (suggestions.length === 1) {
        setCommand(suggestions[0]);
      }
    }
  };

  const getCommandSuggestions = (input: string): string[] => {
    const commands = [
      'help',
      'phishlets list',
      'phishlets enable',
      'phishlets disable',
      'lures list',
      'lures create',
      'lures delete',
      'sessions list',
      'sessions terminate',
      'blacklist add',
      'blacklist remove',
      'blacklist list',
      'clear'
    ];
    return commands.filter(cmd => cmd.startsWith(input.toLowerCase()));
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="flex items-center gap-2 bg-gray-800 rounded px-3 py-2">
        <span className="text-green-400">&gt;</span>
        <input
          ref={inputRef}
          type="text"
          value={command}
          onChange={(e) => setCommand(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 bg-transparent border-none outline-none text-white font-mono"
          placeholder="Type a command or 'help'..."
          autoFocus
          spellCheck={false}
        />
      </div>
    </form>
  );
};

export default CommandInput;