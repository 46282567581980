import React, { useState } from 'react';
import { Plus, Search, RefreshCw } from 'lucide-react';
import { useDomains } from '../../hooks/useDomains';
import DomainCard from './DomainCard';
import DomainForm from './DomainForm';
import { SSLGenerator } from '../../utils/sslGenerator';

const DomainsView: React.FC = () => {
  const { domains, addDomain, updateDomain, deleteDomain, checkSSL } = useDomains();
  const [showForm, setShowForm] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const sslGenerator = SSLGenerator.getInstance();

  const filteredDomains = domains.filter(domain => 
    domain.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    domain.ipAddress.includes(searchTerm)
  );

  const handleRefreshAll = async () => {
    setIsRefreshing(true);
    try {
      await Promise.all(domains.map(async domain => {
        const cert = await sslGenerator.generateCertificate(domain.name);
        updateDomain(domain.id, {
          sslStatus: 'valid',
          expiryDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString()
        });
      }));
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search domains by name or IP..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleRefreshAll}
            disabled={isRefreshing}
            className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors disabled:opacity-50 bg-white border border-gray-200 rounded-lg"
            title="Refresh SSL Status"
          >
            <RefreshCw className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
            <span className="hidden sm:inline">Refresh All</span>
          </button>
          <button
            onClick={() => setShowForm(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span className="hidden sm:inline">Add Domain</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {filteredDomains.map((domain) => (
          <DomainCard
            key={domain.id}
            domain={domain}
            onDelete={deleteDomain}
            onEdit={() => setSelectedDomain(domain.id)}
            onRefresh={() => checkSSL(domain.id)}
          />
        ))}

        {filteredDomains.length === 0 && (
          <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
            <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
              <Plus className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-1">No domains found</h3>
            <p className="text-gray-500">
              {searchTerm ? 'Try adjusting your search terms' : 'Get started by adding a new domain'}
            </p>
          </div>
        )}
      </div>

      {(showForm || selectedDomain) && (
        <DomainForm
          domain={selectedDomain ? domains.find(d => d.id === selectedDomain) : undefined}
          onClose={() => {
            setShowForm(false);
            setSelectedDomain(null);
          }}
          onSubmit={(data) => {
            if (selectedDomain) {
              updateDomain(selectedDomain, data);
            } else {
              addDomain({
                id: Date.now().toString(),
                ...data,
                status: 'inactive',
                phishlets: 0,
                lastChecked: new Date().toISOString()
              });
            }
            setShowForm(false);
            setSelectedDomain(null);
          }}
        />
      )}
    </div>
  );
};

export default DomainsView;