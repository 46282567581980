import React from 'react';
import { LogEntryType } from '../../types';
import { Shield, AlertTriangle, Terminal, Info } from 'lucide-react';

interface LogEntryProps {
  type: LogEntryType;
  content: string;
  timestamp: string;
}

const LogEntry: React.FC<LogEntryProps> = ({ type, content, timestamp }) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <Shield className="w-4 h-4" />;
      case 'error':
        return <AlertTriangle className="w-4 h-4" />;
      case 'command':
        return <Terminal className="w-4 h-4" />;
      default:
        return <Info className="w-4 h-4" />;
    }
  };

  const getTextColor = () => {
    switch (type) {
      case 'error':
        return 'text-red-400';
      case 'success':
        return 'text-green-400';
      case 'command':
        return 'text-yellow-400';
      default:
        return 'text-gray-300';
    }
  };

  return (
    <div className={`py-1 flex items-start gap-2 ${getTextColor()}`}>
      <span className="text-gray-500 min-w-[80px]">
        {new Date(timestamp).toLocaleTimeString()}
      </span>
      <span className="mt-1">{getIcon()}</span>
      <span className="flex-1">
        {type === 'command' ? '>' : ''} {content}
      </span>
    </div>
  );
};

export default LogEntry;