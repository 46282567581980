import React from 'react';
import { Trash2, Edit, RefreshCw, Globe, Shield } from 'lucide-react';
import { Domain } from '../../types';

interface DomainCardProps {
  domain: Domain;
  onDelete: (id: string) => void;
  onEdit: () => void;
  onRefresh: () => void;
}

const DomainCard: React.FC<DomainCardProps> = ({ domain, onDelete, onEdit, onRefresh }) => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <div className="flex items-center gap-3">
            <h3 className="text-lg font-semibold text-gray-900">{domain.name}</h3>
            <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
              domain.status === 'active' 
                ? 'bg-green-100 text-green-700' 
                : 'bg-gray-100 text-gray-700'
            }`}>
              {domain.status}
            </span>
            <span className={`px-2.5 py-1 text-xs font-medium rounded-full flex items-center gap-1 ${
              domain.sslStatus === 'valid'
                ? 'bg-green-100 text-green-700'
                : domain.sslStatus === 'expired'
                ? 'bg-yellow-100 text-yellow-700'
                : 'bg-red-100 text-red-700'
            }`}>
              <Shield className="w-3 h-3" />
              SSL {domain.sslStatus}
            </span>
          </div>
          <div className="flex items-center gap-2 text-gray-500">
            <Globe className="w-4 h-4" />
            <span className="text-sm">{domain.ipAddress}</span>
          </div>
        </div>
        
        <div className="flex gap-2">
          <button 
            onClick={onRefresh}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title="Refresh SSL Status"
          >
            <RefreshCw className="w-5 h-5" />
          </button>
          <button 
            onClick={onEdit}
            className="p-2 text-gray-600 hover:text-yellow-600 hover:bg-yellow-50 rounded-lg transition-colors"
            title="Edit"
          >
            <Edit className="w-5 h-5" />
          </button>
          <button 
            onClick={() => onDelete(domain.id)}
            className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            title="Delete"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4">
        <div>
          <p className="text-xs text-gray-500">Active Phishlets</p>
          <p className="text-sm font-medium text-gray-900">{domain.phishlets}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">SSL Expiry</p>
          <p className="text-sm font-medium text-gray-900">
            {new Date(domain.expiryDate).toLocaleDateString()}
          </p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Last Checked</p>
          <p className="text-sm font-medium text-gray-900">
            {new Date(domain.lastChecked).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DomainCard;