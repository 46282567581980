import { useState, useCallback } from 'react';
import { Settings } from '../types';

const DEFAULT_SETTINGS: Settings = {
  autoStart: true,
  logRetentionDays: 30,
  blacklistEnabled: true,
  maxSessions: 100,
  notificationsEnabled: true,
  proxyPort: 8443,
  sslCertPath: '/certs/default.pem',
  sslKeyPath: '/certs/default.key'
};

export const useSettings = () => {
  const [settings, setSettings] = useState<Settings>(() => {
    const savedSettings = localStorage.getItem('settings');
    return savedSettings ? JSON.parse(savedSettings) : DEFAULT_SETTINGS;
  });

  const updateSetting = useCallback(<K extends keyof Settings>(key: K, value: Settings[K]) => {
    setSettings(prev => {
      const newSettings = { ...prev, [key]: value };
      localStorage.setItem('settings', JSON.stringify(newSettings));
      return newSettings;
    });
  }, []);

  const resetSettings = useCallback(() => {
    setSettings(DEFAULT_SETTINGS);
    localStorage.setItem('settings', JSON.stringify(DEFAULT_SETTINGS));
  }, []);

  return {
    settings,
    updateSetting,
    resetSettings
  };
};