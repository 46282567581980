import React, { useState } from 'react';
import { Download, Filter, Search } from 'lucide-react';
import { useSecurityLogs } from '../../hooks/useSecurityLogs';
import SecurityLogEntry from './SecurityLogEntry';

const SecurityLogsView: React.FC = () => {
  const { logs, exportLogs, filterLogs } = useSecurityLogs();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState<'all' | 'error' | 'warning' | 'info'>('all');

  const filteredLogs = filterLogs(searchTerm, filterType);

  return (
    <div className="space-y-6">
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search logs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value as any)}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Logs</option>
          <option value="error">Errors</option>
          <option value="warning">Warnings</option>
          <option value="info">Info</option>
        </select>
        <button
          onClick={exportLogs}
          className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
        >
          <Download className="w-5 h-5" />
          Export
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="grid grid-cols-4 gap-4 p-4 bg-gray-50 border-b border-gray-200">
          <div className="font-medium text-gray-500">Timestamp</div>
          <div className="font-medium text-gray-500">Type</div>
          <div className="font-medium text-gray-500">Source</div>
          <div className="font-medium text-gray-500">Message</div>
        </div>

        <div className="divide-y divide-gray-200">
          {filteredLogs.map((log) => (
            <SecurityLogEntry key={log.id} log={log} />
          ))}

          {filteredLogs.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              No logs found matching your criteria.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SecurityLogsView;