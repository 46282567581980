import { useState, useCallback } from 'react';
import { Lure } from '../types';

export const useLures = () => {
  const [lures, setLures] = useState<Lure[]>([
    {
      id: '1',
      phishletId: '1',
      path: '/login',
      redirectUrl: 'https://example.com/login',
      params: {
        utm_source: 'email',
        campaign: 'spring2024'
      },
      active: true,
      captures: 5,
      maxVisits: 100,
      currentVisits: 45,
      expiryDate: '2024-12-31T23:59:59Z',
      allowedCountries: ['US', 'CA', 'GB'],
      blockedCountries: ['CN', 'RU']
    },
    {
      id: '2',
      phishletId: '2',
      path: '/auth',
      redirectUrl: 'https://secure-login.net/auth',
      script: 'console.log("Custom injection")',
      active: false,
      captures: 3,
      maxVisits: 50,
      currentVisits: 12,
      expiryDate: '2024-12-31T23:59:59Z'
    }
  ]);

  const addLure = useCallback((lure: Lure) => {
    setLures(prev => [...prev, lure]);
  }, []);

  const updateLure = useCallback((id: string, data: Partial<Lure>) => {
    setLures(prev => prev.map(lure => 
      lure.id === id ? { ...lure, ...data } : lure
    ));
  }, []);

  const toggleLure = useCallback((id: string) => {
    setLures(prev => prev.map(lure => 
      lure.id === id ? { ...lure, active: !lure.active } : lure
    ));
  }, []);

  const deleteLure = useCallback((id: string) => {
    setLures(prev => prev.filter(lure => lure.id !== id));
  }, []);

  const incrementVisits = useCallback((id: string) => {
    setLures(prev => prev.map(lure => {
      if (lure.id === id) {
        const currentVisits = (lure.currentVisits || 0) + 1;
        const active = lure.maxVisits ? currentVisits < lure.maxVisits : true;
        return { ...lure, currentVisits, active };
      }
      return lure;
    }));
  }, []);

  const checkExpiry = useCallback(() => {
    const now = new Date().getTime();
    setLures(prev => prev.map(lure => {
      if (lure.expiryDate && new Date(lure.expiryDate).getTime() < now) {
        return { ...lure, active: false };
      }
      return lure;
    }));
  }, []);

  return {
    lures,
    addLure,
    updateLure,
    toggleLure,
    deleteLure,
    incrementVisits,
    checkExpiry
  };
};