import { useState, useCallback } from 'react';
import { Domain } from '../types';

export const useDomains = () => {
  const [domains, setDomains] = useState<Domain[]>([
    {
      id: '1',
      name: 'example.com',
      status: 'active',
      phishlets: 2,
      sslStatus: 'valid',
      expiryDate: '2024-12-31',
      ipAddress: '192.168.1.100',
      lastChecked: '2024-02-20T10:00:00'
    },
    {
      id: '2',
      name: 'secure-login.net',
      status: 'active',
      phishlets: 1,
      sslStatus: 'valid',
      expiryDate: '2024-11-30',
      ipAddress: '192.168.1.101',
      lastChecked: '2024-02-20T09:30:00'
    }
  ]);

  const addDomain = useCallback((domain: Domain) => {
    setDomains(prev => [...prev, domain]);
  }, []);

  const updateDomain = useCallback((id: string, data: Partial<Domain>) => {
    setDomains(prev => prev.map(domain => 
      domain.id === id ? { ...domain, ...data } : domain
    ));
  }, []);

  const deleteDomain = useCallback((id: string) => {
    setDomains(prev => prev.filter(domain => domain.id !== id));
  }, []);

  const checkSSL = useCallback(async (id: string) => {
    // Simulate SSL check with random status
    const statuses: Domain['sslStatus'][] = ['valid', 'expired', 'invalid'];
    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
    
    setDomains(prev => prev.map(domain => {
      if (domain.id === id) {
        return {
          ...domain,
          sslStatus: randomStatus,
          lastChecked: new Date().toISOString()
        };
      }
      return domain;
    }));

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));
  }, []);

  return {
    domains,
    addDomain,
    updateDomain,
    deleteDomain,
    checkSSL
  };
};