import { useState, useCallback } from 'react';
import { SecurityLog } from '../types';

export const useSecurityLogs = () => {
  const [logs, setLogs] = useState<SecurityLog[]>([
    {
      id: '1',
      type: 'error',
      source: 'phishlet:example.com',
      message: 'Failed to establish SSL connection',
      timestamp: '2024-02-20T10:15:00Z'
    },
    {
      id: '2',
      type: 'warning',
      source: 'session:192.168.1.100',
      message: 'Multiple failed authentication attempts detected',
      timestamp: '2024-02-20T10:10:00Z'
    },
    {
      id: '3',
      type: 'info',
      source: 'system',
      message: 'New phishlet configuration loaded',
      timestamp: '2024-02-20T10:05:00Z'
    }
  ]);

  const addLog = useCallback((log: Omit<SecurityLog, 'id'>) => {
    setLogs(prev => [{
      ...log,
      id: Date.now().toString()
    }, ...prev]);
  }, []);

  const filterLogs = useCallback((searchTerm: string, type: 'all' | 'error' | 'warning' | 'info') => {
    return logs.filter(log => {
      const matchesSearch = searchTerm === '' || 
        log.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.source.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesType = type === 'all' || log.type === type;

      return matchesSearch && matchesType;
    });
  }, [logs]);

  const exportLogs = useCallback(() => {
    const logText = logs
      .map(log => `[${new Date(log.timestamp).toISOString()}] ${log.type.toUpperCase()} - ${log.source}: ${log.message}`)
      .join('\n');

    const blob = new Blob([logText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `security-logs-${new Date().toISOString()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [logs]);

  return {
    logs,
    addLog,
    filterLogs,
    exportLogs
  };
};