import React from 'react';
import { useSessions } from '../../hooks/useSessions';
import SessionCard from './SessionCard';

const SessionsView: React.FC = () => {
  const { sessions, terminateSession } = useSessions();

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <input
          type="text"
          placeholder="Search sessions..."
          className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <select className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option value="all">All Sessions</option>
          <option value="active">Active</option>
          <option value="expired">Expired</option>
        </select>
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="grid grid-cols-7 gap-4 p-4 border-b border-gray-200 bg-gray-50 rounded-t-xl">
          <div className="font-medium text-gray-500">Username</div>
          <div className="font-medium text-gray-500">Domain</div>
          <div className="font-medium text-gray-500">IP Address</div>
          <div className="font-medium text-gray-500">Start Time</div>
          <div className="font-medium text-gray-500">Status</div>
          <div className="font-medium text-gray-500">Tokens</div>
          <div className="font-medium text-gray-500">Actions</div>
        </div>
        
        <div className="divide-y divide-gray-200">
          {sessions.map(session => (
            <SessionCard
              key={session.id}
              session={session}
              onTerminate={terminateSession}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SessionsView;