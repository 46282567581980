import { Activity } from '../../types';

export const activities: Activity[] = [
  {
    type: 'success',
    message: 'New session captured from target domain',
    timestamp: '2 minutes ago',
    domain: 'secure-login.example.com'
  },
  {
    type: 'warning',
    message: 'Multiple failed authentication attempts detected',
    timestamp: '15 minutes ago',
    domain: 'auth.example.net'
  },
  {
    type: 'success',
    message: 'Credentials successfully captured',
    timestamp: '1 hour ago',
    domain: 'login.example.org'
  }
];