import React from 'react';
import { XCircle, Download } from 'lucide-react';
import { Session } from '../../types';
import { formatDate } from '../../utils/formatDate';

interface SessionCardProps {
  session: Session;
  onTerminate: (id: string) => void;
}

const SessionCard: React.FC<SessionCardProps> = ({ session, onTerminate }) => {
  return (
    <div className="grid grid-cols-7 gap-4 p-4 items-center hover:bg-gray-50">
      <div className="text-sm font-medium text-gray-900">{session.username}</div>
      <div className="text-sm text-gray-500">{session.domain}</div>
      <div className="text-sm text-gray-500">{session.ip}</div>
      <div className="text-sm text-gray-500">{formatDate(session.startTime)}</div>
      <div>
        <span className={`px-2 py-1 text-xs rounded-full ${
          session.status === 'active'
            ? 'bg-green-100 text-green-700'
            : 'bg-gray-100 text-gray-700'
        }`}>
          {session.status}
        </span>
      </div>
      <div className="text-sm text-gray-500">{session.tokens}</div>
      <div className="flex gap-2">
        <button
          onClick={() => onTerminate(session.id)}
          className="p-1 text-gray-400 hover:text-red-600 transition-colors"
          title="Terminate Session"
        >
          <XCircle className="w-5 h-5" />
        </button>
        <button
          className="p-1 text-gray-400 hover:text-blue-600 transition-colors"
          title="Download Tokens"
        >
          <Download className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default SessionCard;