import React from 'react';
import { Power, Download, Trash2, Eye, Globe } from 'lucide-react';
import { Phishlet } from '../../types';

interface PhishletListViewProps {
  phishlets: Phishlet[];
  onToggle: (id: string) => void;
  onDelete: (id: string) => void;
  onExport: (id: string) => void;
}

const PhishletListView: React.FC<PhishletListViewProps> = ({
  phishlets,
  onToggle,
  onDelete,
  onExport
}) => {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="hidden md:grid md:grid-cols-6 gap-4 p-4 bg-gray-50 border-b border-gray-200">
        <div className="font-medium text-gray-500">Name</div>
        <div className="font-medium text-gray-500">Hostname</div>
        <div className="font-medium text-gray-500">Status</div>
        <div className="font-medium text-gray-500">Captures</div>
        <div className="font-medium text-gray-500">Last Active</div>
        <div className="font-medium text-gray-500">Actions</div>
      </div>

      <div className="divide-y divide-gray-200">
        {phishlets.map((phishlet) => (
          <div key={phishlet.id} className="block md:grid md:grid-cols-6 gap-4 p-4 hover:bg-gray-50">
            <div className="flex items-center justify-between md:justify-start gap-2 mb-2 md:mb-0">
              <span className="font-medium text-gray-900">{phishlet.name}</span>
              <div className="flex gap-2 md:hidden">
                <button
                  onClick={() => onToggle(phishlet.id)}
                  className={`p-1 rounded-lg transition-colors ${
                    phishlet.status === 'enabled'
                      ? 'text-green-600 hover:bg-green-50'
                      : 'text-gray-400 hover:bg-gray-50'
                  }`}
                >
                  <Power className="w-4 h-4" />
                </button>
                <button
                  onClick={() => onDelete(phishlet.id)}
                  className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>

            <div className="flex items-center gap-2 text-gray-500 mb-2 md:mb-0">
              <Globe className="w-4 h-4" />
              <span className="text-sm">{phishlet.hostname}</span>
            </div>

            <div className="mb-2 md:mb-0">
              <span className={`inline-flex items-center px-2.5 py-1 text-xs font-medium rounded-full ${
                phishlet.status === 'enabled'
                  ? 'bg-green-100 text-green-700'
                  : 'bg-gray-100 text-gray-700'
              }`}>
                {phishlet.status}
              </span>
            </div>

            <div className="text-gray-600 mb-2 md:mb-0">{phishlet.captures}</div>
            <div className="text-gray-600 mb-2 md:mb-0">{phishlet.lastActive}</div>

            <div className="hidden md:flex gap-2">
              <button
                onClick={() => onToggle(phishlet.id)}
                className={`p-2 rounded-lg transition-colors ${
                  phishlet.status === 'enabled'
                    ? 'text-green-600 hover:bg-green-50'
                    : 'text-gray-400 hover:bg-gray-50'
                }`}
                title={phishlet.status === 'enabled' ? 'Disable' : 'Enable'}
              >
                <Power className="w-5 h-5" />
              </button>
              <button
                onClick={() => onExport(phishlet.id)}
                className="p-2 text-gray-400 hover:text-blue-600 hover:bg-blue-50 rounded-lg"
                title="Export"
              >
                <Download className="w-5 h-5" />
              </button>
              <button
                onClick={() => onDelete(phishlet.id)}
                className="p-2 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-lg"
                title="Delete"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhishletListView;