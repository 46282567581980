import React, { useState } from 'react';
import { Plus, Search, Filter, List, Grid } from 'lucide-react';
import { usePhishlets } from '../../hooks/usePhishlets';
import PhishletCard from './PhishletCard';
import PhishletForm from './PhishletForm';
import PhishletListView from './PhishletListView';

const PhishletsView: React.FC = () => {
  const { phishlets, togglePhishlet, deletePhishlet, exportPhishlet } = usePhishlets();
  const [showNewPhishletForm, setShowNewPhishletForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState<'all' | 'enabled' | 'disabled'>('all');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  const filteredPhishlets = phishlets.filter(phishlet => {
    const matchesSearch = phishlet.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         phishlet.hostname.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || phishlet.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search phishlets..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setViewMode(prev => prev === 'grid' ? 'list' : 'grid')}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            title={viewMode === 'grid' ? 'Switch to List View' : 'Switch to Grid View'}
          >
            {viewMode === 'grid' ? (
              <List className="w-5 h-5" />
            ) : (
              <Grid className="w-5 h-5" />
            )}
          </button>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value as any)}
            className="pl-10 pr-8 py-2.5 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none min-w-[150px]"
          >
            <option value="all">All Status</option>
            <option value="enabled">Enabled</option>
            <option value="disabled">Disabled</option>
          </select>
          <button
            onClick={() => setShowNewPhishletForm(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span className="hidden sm:inline">New Phishlet</span>
          </button>
        </div>
      </div>

      {viewMode === 'grid' ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredPhishlets.map((phishlet) => (
            <PhishletCard
              key={phishlet.id}
              phishlet={phishlet}
              onToggle={togglePhishlet}
              onDelete={deletePhishlet}
              onExport={exportPhishlet}
            />
          ))}
        </div>
      ) : (
        <PhishletListView
          phishlets={filteredPhishlets}
          onToggle={togglePhishlet}
          onDelete={deletePhishlet}
          onExport={exportPhishlet}
        />
      )}

      {filteredPhishlets.length === 0 && (
        <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
          <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
            <Filter className="w-8 h-8 text-gray-400" />
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-1">No phishlets found</h3>
          <p className="text-gray-500">
            {searchTerm ? 'Try adjusting your search terms' : 'Get started by creating a new phishlet'}
          </p>
        </div>
      )}

      {showNewPhishletForm && (
        <PhishletForm onClose={() => setShowNewPhishletForm(false)} />
      )}
    </div>
  );
};

export default PhishletsView;