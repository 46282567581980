import { useState, useCallback } from 'react';
import { Session } from '../types';

export const useSessions = () => {
  const [sessions, setSessions] = useState<Session[]>([
    {
      id: '1',
      username: 'user@example.com',
      domain: 'example.com',
      ip: '192.168.1.100',
      startTime: '2024-02-20T10:30:00',
      status: 'active',
      tokens: 3
    },
    {
      id: '2',
      username: 'admin@secure-login.net',
      domain: 'secure-login.net',
      ip: '192.168.1.101',
      startTime: '2024-02-20T10:25:00',
      status: 'active',
      tokens: 5
    }
  ]);

  const terminateSession = useCallback((id: string) => {
    setSessions(prev => prev.filter(session => session.id !== id));
  }, []);

  return { sessions, terminateSession };
};