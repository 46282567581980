import { Shield, Target, Activity, Users, Database, Terminal, AlertTriangle, Settings, Globe, Link, Key } from 'lucide-react';
import { MenuItem } from '../../types';

export const menuItems: MenuItem[] = [
  { icon: Shield, label: 'Dashboard' },
  { icon: Target, label: 'Phishlets' },
  { icon: Globe, label: 'Domains' },
  { icon: Link, label: 'Lures' },
  { icon: Activity, label: 'Active Sessions' },
  { icon: Key, label: 'Captured Data' },
  { icon: Database, label: 'Blacklist' },
  { icon: Terminal, label: 'Console' },
  { icon: AlertTriangle, label: 'Security Logs' },
  { icon: Settings, label: 'Settings' },
];