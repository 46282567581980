export const parsePhishletFile = (content: string) => {
  try {
    // Parse YAML/JSON content
    const data = JSON.parse(content);

    // Validate required fields
    const requiredFields = ['name', 'hostname', 'auth_urls', 'cred_fields'];
    const missingFields = requiredFields.filter(field => !data[field]);
    
    if (missingFields.length > 0) {
      throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }

    // Transform to internal format
    return {
      name: data.name,
      hostname: data.hostname,
      authUrls: data.auth_urls,
      credFields: {
        username: Array.isArray(data.cred_fields.username) 
          ? data.cred_fields.username 
          : [data.cred_fields.username],
        password: Array.isArray(data.cred_fields.password)
          ? data.cred_fields.password
          : [data.cred_fields.password]
      },
      proxyHosts: data.proxy_hosts || [],
      subfilters: data.subfilters || [],
      jsInjects: data.js_injects || [],
      authTokens: data.auth_tokens || []
    };
  } catch (error) {
    throw new Error('Invalid phishlet file format');
  }
};