import { EventEmitter } from './EventEmitter';

interface Certificate {
  publicKey: string;
  privateKey: string;
  cert: string;
  fingerprint: string;
}

export class SSLGenerator extends EventEmitter {
  private static instance: SSLGenerator;
  private certificates: Map<string, Certificate> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): SSLGenerator {
    if (!SSLGenerator.instance) {
      SSLGenerator.instance = new SSLGenerator();
    }
    return SSLGenerator.instance;
  }

  async generateCertificate(domain: string): Promise<Certificate> {
    try {
      // For browser context, we'll use Web Crypto API
      const keyPair = await window.crypto.subtle.generateKey(
        {
          name: 'ECDSA',
          namedCurve: 'P-256'
        },
        true,
        ['sign', 'verify']
      );

      // Export public key
      const publicKeyBuffer = await window.crypto.subtle.exportKey(
        'spki',
        keyPair.publicKey
      );
      const publicKey = this.arrayBufferToBase64(publicKeyBuffer);

      // Export private key
      const privateKeyBuffer = await window.crypto.subtle.exportKey(
        'pkcs8',
        keyPair.privateKey
      );
      const privateKey = this.arrayBufferToBase64(privateKeyBuffer);

      // Generate a simple self-signed certificate structure
      const cert = this.generateSelfSignedCert(domain, publicKey);

      // Calculate fingerprint
      const encoder = new TextEncoder();
      const certBuffer = encoder.encode(cert);
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', certBuffer);
      const fingerprint = Array.from(new Uint8Array(hashBuffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join(':');

      const certificate: Certificate = {
        publicKey,
        privateKey,
        cert,
        fingerprint
      };

      this.certificates.set(domain, certificate);
      this.emit('certificate:generated', { domain, fingerprint });

      return certificate;
    } catch (error) {
      this.emit('error', {
        message: 'Failed to generate certificate',
        error
      });
      throw error;
    }
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private generateSelfSignedCert(domain: string, publicKey: string): string {
    const now = new Date();
    const exp = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year

    return JSON.stringify({
      version: 3,
      serialNumber: Date.now().toString(16),
      subject: {
        commonName: domain
      },
      issuer: {
        commonName: domain
      },
      notBefore: now.toISOString(),
      notAfter: exp.toISOString(),
      publicKey: publicKey,
      extensions: [
        {
          id: '2.5.29.17',
          value: [`DNS:${domain}`]
        }
      ]
    }, null, 2);
  }

  getCertificate(domain: string): Certificate | undefined {
    return this.certificates.get(domain);
  }

  getAllCertificates(): Certificate[] {
    return Array.from(this.certificates.values());
  }

  removeCertificate(domain: string): boolean {
    return this.certificates.delete(domain);
  }

  destroy() {
    this.certificates.clear();
    this.removeAllListeners();
  }
}