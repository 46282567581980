import React from 'react';
import { Shield } from 'lucide-react';
import { menuItems } from './MenuItems';

interface SidebarProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="h-screen w-64 bg-gray-900 text-white p-4 fixed left-0 top-0">
      <div className="flex items-center gap-2 mb-8 px-2">
        <Shield className="w-8 h-8 text-red-500" />
        <span className="text-xl font-bold">SecOps Portal</span>
      </div>
      
      <nav className="space-y-1">
        {menuItems.map((item, index) => (
          <div
            key={index}
            onClick={() => onTabChange(item.label)}
            className={`flex items-center gap-3 px-4 py-3 rounded-lg cursor-pointer transition-colors ${
              activeTab === item.label 
                ? 'bg-red-500 text-white' 
                : 'hover:bg-gray-800'
            }`}
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;