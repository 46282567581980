import React from 'react';
import { Settings } from '../../types';

interface SettingsFormProps {
  settings: Settings;
  onSettingChange: (key: keyof Settings, value: any) => void;
}

const SettingsForm: React.FC<SettingsFormProps> = ({ settings, onSettingChange }) => {
  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">General Settings</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <label className="block text-sm font-medium text-gray-700">Auto-start Phishlets</label>
              <p className="text-sm text-gray-500">Automatically start phishlets when created</p>
            </div>
            <div className="relative inline-block w-12 h-6">
              <input
                type="checkbox"
                checked={settings.autoStart}
                onChange={e => onSettingChange('autoStart', e.target.checked)}
                className="sr-only peer"
                id="autoStart"
              />
              <label
                htmlFor="autoStart"
                className="absolute inset-0 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 peer-checked:bg-blue-500"
              >
                <span className="absolute inset-y-0 left-0 w-6 h-6 bg-white rounded-full shadow transform transition-transform duration-200 peer-checked:translate-x-6" />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Log Retention (Days)</label>
            <input
              type="number"
              value={settings.logRetentionDays}
              onChange={e => onSettingChange('logRetentionDays', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              min="1"
              max="365"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Maximum Active Sessions</label>
            <input
              type="number"
              value={settings.maxSessions}
              onChange={e => onSettingChange('maxSessions', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              min="1"
              max="1000"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Security Settings</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <label className="block text-sm font-medium text-gray-700">IP Blacklist</label>
              <p className="text-sm text-gray-500">Enable IP blacklisting functionality</p>
            </div>
            <div className="relative inline-block w-12 h-6">
              <input
                type="checkbox"
                checked={settings.blacklistEnabled}
                onChange={e => onSettingChange('blacklistEnabled', e.target.checked)}
                className="sr-only peer"
                id="blacklistEnabled"
              />
              <label
                htmlFor="blacklistEnabled"
                className="absolute inset-0 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 peer-checked:bg-blue-500"
              >
                <span className="absolute inset-y-0 left-0 w-6 h-6 bg-white rounded-full shadow transform transition-transform duration-200 peer-checked:translate-x-6" />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Proxy Port</label>
            <input
              type="number"
              value={settings.proxyPort}
              onChange={e => onSettingChange('proxyPort', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              min="1"
              max="65535"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">SSL Certificate Path</label>
            <input
              type="text"
              value={settings.sslCertPath}
              onChange={e => onSettingChange('sslCertPath', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">SSL Key Path</label>
            <input
              type="text"
              value={settings.sslKeyPath}
              onChange={e => onSettingChange('sslKeyPath', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsForm;